import { lazy, Suspense } from "react";
import { createBrowserRouter, Navigate, redirect } from "react-router-dom";
import Loader from "../components/Loader";

const RootLayout = lazy(() => import("../layout/RootLayout"));
const Landing = lazy(() => import("../pages/landing"));
const Services = lazy(() => import("../pages/services"));
const Service = lazy(() => import("../pages/service"));
const About = lazy(() => import("../pages/about"));
const PrivacyPolicy = lazy(() => import("../pages/other/PrivacyPolicy"));

const publicRoutes = createBrowserRouter([
  {
    path: "/",
    element: (
      <Suspense fallback={<Loader />}>
        <RootLayout />
      </Suspense>
    ),
    children: [
      { path: "/", element: <Landing /> },
      {
        path: "/about",
        element: <About />,
      },
      {
        path: "/service",
        element: <Services />,
      },
      {
        path: "/service/:service",
        element: <Service />,
      },
      {
        path: "/privacypolicy",
        element: <PrivacyPolicy />,
      },
      {
        path: "*",
        element: <Navigate to="/" replace />,
      },
    ],
  },
]);

export default publicRoutes;
