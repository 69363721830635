import { RouterProvider } from "react-router-dom";

import router from "./routes/Routes";
import { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";

function App() {

  useEffect(() => {
    AOS.init();
  }, []);

  return <RouterProvider router={router} />;
}

export default App;
